import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Privacy from "../components/Privacy/Privacy"

export default function ResourcesPage() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Privacy />
    </Layout>
  )
}
