import React from "react"

export default function Privacy() {
  return (
    <div className="privacy-container">
      <h3>Privacy Policy</h3>
      <p className="date">Last updated [February 14, 2021]</p>
      <p>
        {" "}
        Ryse Network ("we") operates ryse.network (the "Site"). This page
        informs you of our policies regarding the collection, use and disclosure
        of Personal Information we receive from users of the Site. We use your
        Personal Information only for providing and improving the Site. By using
        the Site, you agree to the collection and use of information in
        accordance with this policy.
      </p>
      <p>
        <strong>Information Collection and Use</strong>
      </p>
      <p>
        While using our Site, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. Personally identifiable information , including but not
        limited to first name, last name, email address.
      </p>
      <p>
        The site does use third party services that may collect information used
        to identify you.
      </p>
      <div>
        <p>
          Links to the privacy policies of third party service providers used by
          the site:
        </p>
        <ul>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://policies.google.com/privacy"
              >
                Google Analytics
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.mailerlite.com/legal/privacy-policy"
              >
                MailerLite
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.netlify.com/privacy/"
              >
                Netlify
              </a>
            </p>
          </li>
        </ul>
      </div>
      <p>
        <strong>Log Data</strong>
      </p>
      <p>
        {" "}
        Like many site operators, we collect information that your browser sends
        whenever you visit our Site ("Log Data"). This Log Data may include
        information such as your computer's Internet Protocol ("IP") address,
        browser type, browser version, the pages of our Site that you visit, the
        time and date of your visit, the time spent on those pages and other
        statistics. In addition, we may use third party services such as Google
        Analytics that collect, monitor and analyze this …
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Cookies are files with small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a web
        site and stored on your computer's hard drive. Like many sites, we use
        "cookies" to collect information. You can instruct your browser to
        refuse all cookies or to indicate when a cookie is being sent. However,
        if you do not accept cookies, you may not be able to use some portions
        of our Site.
      </p>
      <p>
        <strong>Service Providers</strong>
      </p>
      <p>
        {" "}
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>
        {" "}
        We want to inform users of this Service that these third parties have
        access to your Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>
      <p>
        <strong>Security</strong>
      </p>
      <p>
        {" "}
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>
      <p>
        <strong>Links to Other Sites</strong>
      </p>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <p>
        <strong>Children’s Privacy</strong>
      </p>
      <p>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. In the case we discover that a child under 13 has provided us
        with personal information, we immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will
        be able to do necessary actions.
      </p>
      <p>
        <strong>Changes to This Privacy Policy</strong>
      </p>
      <p>
        {" "}
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
      </p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us.
      </p>
    </div>
  )
}
